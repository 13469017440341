function isFreePlan(item, planType) {
    let isFree = false
    const price = item.price
    for (let i = 0; i < price.length; i++) {
        const item = price[i]
        if (item.pricing_model === 'FIXED') {
            if (planType === 'MONTHLY') {
                const actualPrice = item.frequency.month[0]
                isFree = actualPrice.price === 0
            } else {
                const actualPrice = item.frequency.year[0]
                isFree = actualPrice.price === 0
            }
        }
        if (item.pricing_model !== 'FIXED') {
            isFree = false;
            break
        }
    }
    return isFree
}

module.exports = {isFreePlan}
