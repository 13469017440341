<template>
  <div class="bgTheme">
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications group="plan_preview"/>
    <b-overlay :show="showLoader" rounded="sm" variant="light" :opacity="1" spinner-variant="dark">
      <div style="height: 100px" v-if="showLoader"></div>
      <div class="row p-5 justify-content-center" style="margin-left: 0px; margin-right: 0px;  "
           :style="{'background-color':getBackgroundColor}" v-if="!showLoader">
        <div class="col-xl-12 mb-5">
          <div class="col-xl-2 mx-auto tab-center center-div-tab">
            <b-tabs class="border_radius" pills fill :nav-class="getClassName"
                    :nav-wrapper-class="getNavWrapperClassName"
                    active-nav-item-class="class1"
                    :style="{'background-color':getSwitchBackgroundColor, ...cssAttrs}">
              <b-tab v-if="showMonthlyTab" active @click="tabSelect('MONTHLY')">
                <template v-slot:title>
                  <i class="bx bx-chat font-size-20 d-sm-none"></i>
                  <span class="font_style d-sm-block">Monthly</span>
                </template>
              </b-tab>
              <b-tab v-if="showYearlyTab" @click="tabSelect('YEARLY')">
                <template v-slot:title>
                  <i class="bx bx-group font-size-20 d-sm-none"></i>
                  <span class="font_style d-sm-block">Yearly</span>
                </template>
              </b-tab>
            </b-tabs>
          </div>
        </div>

        <div
            :class="{'col-xl-12': callFrom==='edit', 'col-xl-4': callFrom!=='edit', 'ov-month-wrapper': selectedTab === 'MONTHLY', 'ov-year-wrapper': selectedTab === 'YEARLY'}"
            v-for="(plan, index) in filteredPlans"
            :key="plan.id + refreshKey">
          <Card :plan-type="selectedTab" :item="plan" :index="index" :key="refreshKey"
                :company-id="companyId"
                :country-id="countryId" :token="token" :api-key="apiKey" :customer-identifier="customerIdentifier"
                :email="email"
                :text-color="getTextColor"
                :currency-symbol="currencySymbol"
                :customer-details="customerDetails"
                :workflow-settings="finalWorkflowSettings"
                :mode="mode"
                :country-code="countryCode"
                :zip-code="zipCode"/>
        </div>

      </div>
    </b-overlay>
  </div>
</template>

<script>
import Card from './Card'
import axios from 'axios'

export default {
  props: {
    companyId: {
      type: String
    },
    countryId: {
      type: String
    },
    customerIdentifier: {
      type: String
    },
    email: {
      type: String
    },
    status: {
      type: String
    },
    token: {
      type: String
    },
    apiKey: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    switchBackgroundColor: {
      type: String
    },
    textColor: {
      type: String
    },
    formData: {
      type: String
    },
    callFrom: {
      type: String
    },
    workflowSettings: {
      type: String
    },
    mode: {
      type: String
    },
    countryCode: {
      type: String
    },
    zipCode: {
      type: String
    }
  },
  computed: {
    cssAttrs() {
      return {
        '--backgroundColor': this.getBackgroundColor,
        '--switchBackgroundColor': this.getSwitchBackgroundColor,
      }
    },
    getBackgroundColor() {
      return this.backgroundColor
          || (this.mode === 'DARK' ? this.settings?.dark_mode?.background_color : this.settings.background_color)
          || '#4716df'
    },
    getSwitchBackgroundColor() {
      return this.switchBackgroundColor
          || (this.mode === 'DARK' ? this.settings?.dark_mode?.switch_background_color : this.settings.switch_background_color)
          || '#4716df'
    },
    getTextColor() {
      return this.textColor
          || (this.mode === 'DARK' ? this.settings?.dark_mode?.text_color : this.settings.text_color)
          || '#ffffff'
    },
    getClassName() {
      if (!(this.showMonthlyTab && this.showYearlyTab))
        return 'only-one-tab'
      return ''
    },
    getNavWrapperClassName() {
      if (!(this.showMonthlyTab && this.showYearlyTab))
        return ''
      return 'pqw'
    }
  },
  data() {
    return {
      refreshKey: 0,
      plans: [],
      filteredPlans: [],
      yearlyPlans: [],
      showLoader: false,
      selectedTab: 'MONTHLY',
      customerDetails: null,
      settings: {},
      currencySymbol: null,
      finalWorkflowSettings: {},
      showMonthlyTab: false,
      showYearlyTab: false
    }
  },
  components: {
    Card
  },
  watch: {
    selectedTab: async function () {
      const freq = this.selectedTab === 'MONTHLY' ? 'month' : 'year'
      this.filteredPlans = await this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), freq)
      this.refreshKey++
    }
  },
  methods: {
    async getPlanList() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
      }
      const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/plans?status=${this.status}`
      const {data} = await axios.get(url, {
        headers: headers
      })
      if (data.status) {
        this.plans = data.value
      }
    },
    tabSelect(tab) {
      this.selectedTab = tab
    },
    async getCustomerDetails() {
      try {
        if (this.customerIdentifier) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/customer/${this.customerIdentifier}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async getColorSettings() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=COLOR_SETTINGS`, {
          headers: headers
        })
        if (data && data.value) {
          this.settings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getWorkflowSettings() {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
        }
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.companyId}/country/${this.countryId}/settings?type=WORKFLOW_SETTINGS`, {
          headers: headers
        })
        if (data && data.value) {
          this.finalWorkflowSettings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCompanyDetails() {
      try {
        if (this.countryId) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey ? this.apiKey : this.token}`
          }
          const url = `${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/country/${this.countryId}`
          const {data} = await axios.get(url, {
            headers: headers
          })
          return data.value
        }
      } catch (e) {
        console.error(e)
      }
      return null
    },
    async cleanUpPlans(plans, freq) {
      let finalPlans = []
      for (let i = 0; i < plans.length; i++) {
        const plan = plans[i]
        let price = plan.price;
        if (!price) {
          continue
        }
        let j = 0;
        price.forEach(p => {
          const month = p.frequency[freq]
          plans[i].price[j].frequency[freq] = month.filter(m => m.price !== null && m.price !== "")
          j++
        })

        price = plans[i].price;
        const filtered = price.filter(p => {
          const month = p.frequency[freq]
          return month.length
        })
        if (filtered && filtered.length) {
          plan.price = filtered
          finalPlans.push(plan)
        }
      }
      return finalPlans
    }
  },
  async mounted() {
    let dataPlan = this.formData ? JSON.parse(this.formData) : null
    this.showLoader = !dataPlan
    if (dataPlan) {
      this.plans.push({...dataPlan})
      this.currencySymbol = dataPlan.currency
      this.finalWorkflowSettings = (this.workflowSettings && JSON.parse(this.workflowSettings).setting) || {}
    } else {
      await this.getColorSettings()
      await this.getWorkflowSettings()
      await this.getPlanList()
      this.customerDetails = await this.getCustomerDetails();
      const countryDetails = await this.getCompanyDetails();
      this.currencySymbol = countryDetails.currency_symbol;
    }

    this.filteredPlans = await this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), 'month')
    if (this.filteredPlans.length > 0) {
      this.showMonthlyTab = true
    }

    const yearlyPlans = await this.cleanUpPlans(JSON.parse(JSON.stringify(this.plans)), 'year')
    if (yearlyPlans.length > 0) {
      this.showYearlyTab = true
      if (!this.showMonthlyTab) {
        this.filteredPlans = yearlyPlans
        this.selectedTab = 'YEARLY'
      }
    }

    this.showLoader = false
  }
}
</script>

<style>

.only-one-tab {
  display: none !important;
}

.pqw {
  width: fit-content !important;
  padding: 4px;
  border-radius: 7px;
}

.bgTheme {
  color: white;
  height: 100% !important;
}

.border_radius {
  width: fit-content !important;
  border-radius: 0.35rem !important;
}

.font_style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.nav-link {
  color: white !important;
}

.class1 {
  background: white !important;
  color: var(--switchBackgroundColor) !important;
}

.nav {
  flex-wrap: nowrap !important;
}

.tab-center {
  text-align: -moz-center;
  text-align: -webkit-center;
}

.center-div-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

</style>
